(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-background/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-background/assets/javascripts/init.js');

'use strict';

svs.banner_groups.banner_background.init = options => {
  const $el = options.$el;
  if ($el && $el.length > 0) {
    new svs.banner_common.banner_helpers.Form($el);

    $el.find('[data-type]').each((_, item) => {
      var _item$dataset, _svs$banner_layouts, _svs$banner_layouts2, _svs$banner_layouts3, _svs$banner_layouts4;
      switch ((_item$dataset = item.dataset) === null || _item$dataset === void 0 ? void 0 : _item$dataset.type) {
        case 'section-text-image':
          (_svs$banner_layouts = svs.banner_layouts) === null || _svs$banner_layouts === void 0 || _svs$banner_layouts.section_text_image.init();
          break;
        case 'section-list-image':
          (_svs$banner_layouts2 = svs.banner_layouts) === null || _svs$banner_layouts2 === void 0 || _svs$banner_layouts2.section_list_image.init();
          break;
        case 'section-odds-live':
          (_svs$banner_layouts3 = svs.banner_layouts) === null || _svs$banner_layouts3 === void 0 || _svs$banner_layouts3.section_odds_live.init();
          break;
        case 'section-odds-prepack':
          (_svs$banner_layouts4 = svs.banner_layouts) === null || _svs$banner_layouts4 === void 0 || _svs$banner_layouts4.section_odds_prepack.init();
          break;
        default:
          break;
      }
    });
  }
};

 })(window);